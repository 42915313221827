module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"강남 하이킥","short_name":"강남 하이킥","description":"강남 하이킥 레깅스룸의 공식 사이트입니다. 항상 최고의 마인드 아가씨를 보여드리겠습니다. 방문하기 전에 꼭 전화 부탁드립니다. 1인 기준 40만원.","start_url":"/","display":"standalone","theme_color":"#000000","background_color":"#000000","lang":"ko","orientation":"portrait-primary","icon":"./src/static/images/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b7ac373296446e04fc0e41291b70bdc2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
